import React, { Component } from 'react';
import { Button } from '@blendle/lego';
import PropTypes from 'prop-types';
import { prop, compose, path, equals } from 'ramda';
import { history } from 'byebye';
import withRouter from 'react-router/lib/withRouter';
import {
  STATUS_OK,
  STATUS_PENDING,
  STATUS_ERROR,
  SIGNUP_PLATFORM_FACEBOOK,
  REDIRECT_TO_URL,
} from 'app-constants';
import { translate } from 'instances/i18n';
import Analytics from 'instances/analytics';
import googleAnalytics from 'instances/google_analytics';
import { shouldTrackGAClickEvent } from 'helpers/premiumOnboardingEvents';
import LoginActions from 'actions/LoginActions';
import ApplicationActions from 'actions/ApplicationActions';
import SignUpActions from 'actions/SignUpActions';
import Logo from 'components/Logo';
import Link from 'components/Link';
import FacebookConnect from 'components/facebookConnect/FacebookConnect';

const data = prop('data');
const userId = compose(
  prop('user_id'),
  data,
);
const itemIdFromData = compose(
  prop('item_id'),
  data,
);
const nameFromProps = compose(
  prop('name'),
  data,
);
const loginStatus = path(['loginState', 'login', 'status']);
const loginEmailStatus = path(['loginState', 'loginEmail', 'status']);
const isStatusOk = equals(STATUS_OK);
const isStatusPending = equals(STATUS_PENDING);
const isStatusError = equals(STATUS_ERROR);
const isLoginStatusOk = compose(
  isStatusOk,
  loginStatus,
);
const isLoginStatusPending = compose(
  isStatusPending,
  loginStatus,
);
const isLoginStatusError = compose(
  isStatusError,
  loginStatus,
);
const isLoginEmailStatusOk = compose(
  isStatusOk,
  loginEmailStatus,
);
const isLoginEmailStatusError = compose(
  isStatusError,
  loginEmailStatus,
);
const password = prop('password');

const trackGA = (instance, pathname, action, label, value) => {
  if (shouldTrackGAClickEvent(pathname)) {
    instance.trackEvent(pathname, action, label, value);
  }
};

function onNotMe() {
  Analytics.track('Email login/not me');
}

class EmailLoginPage extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    loginState: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  state = {
    password: '',
    isSend: false,
    isResend: false,
  };

  componentDidUpdate(prevProps) {
    if (isLoginStatusOk(this.props)) {
      this.onLogin();
    }

    if (isLoginStatusError(this.props) && loginStatus(prevProps) !== STATUS_ERROR) {
      Analytics.track('Login Failed');
    }
  }

  onLogin = () => {
    setTimeout(() => {
      const { router } = this.props;

      router.push(`/item/${itemIdFromData(this.props)}`);
    });
  };

  onSubmit = ev => {
    ev.preventDefault();

    LoginActions.loginWithCredentials(
      {
        login: userId(this.props),
        password: password(this.state),
      },
      { login_type: 'manual' },
    );
  };

  onInitialSend = ev => {
    ev.preventDefault();

    const itemId = itemIdFromData(this.props);
    LoginActions.sendLoginEmail(userId(this.props), itemId, `/item/${itemId}`);

    this.setState({
      isSend: true,
    });
  };

  onResend = ev => {
    ev.preventDefault();

    Analytics.track('Email login/resend');

    const itemId = itemIdFromData(this.props);
    LoginActions.sendLoginEmail(userId(this.props), itemId, `/item/${itemId}`);

    this.setState({
      isResend: true,
    });
  };

  onPasswordChange = ev => {
    this.setState({
      password: ev.target.value,
    });
  };

  onFacebookSignup = () => {
    const redirectUrl = `/item/${itemIdFromData(this.props)}?verified=true`;

    ApplicationActions.set(REDIRECT_TO_URL, redirectUrl);
    SignUpActions.setSignupPlatform(SIGNUP_PLATFORM_FACEBOOK);

    history.navigate(
      history.fragment,
      { trigger: true, replace: true },
      { returnUrl: '/signup/deeplink' },
    );

    trackGA(googleAnalytics, window.location.pathname, 'button', 'facebook signup');
  };

  renderResendLink() {
    const { isResend } = this.state;

    if (isLoginEmailStatusOk(this.props) && isResend) {
      return <span>{translate('app.email_login.resend_success')}</span>;
    }

    if (isLoginEmailStatusError(this.props)) {
      return (
        <Link href="#" onClick={this.onResend}>
          {translate('app.email_login.resend_error')}
        </Link>
      );
    }

    return (
      <Link href="#" onClick={this.onResend}>
        {translate('app.email_login.resend')}
      </Link>
    );
  }

  renderLoginForm() {
    const showloginButton = !!password(this.state);
    let errorMessage;

    if (isLoginStatusError(this.props)) {
      errorMessage = (
        <div className="error-message visible">
          {translate('app.email_login.incorrect_password')}
        </div>
      );
    }

    return (
      <div className="login-form">
        <div className="body">
          <h2>{translate('app.email_login.password_message')}</h2>
          <form name="login" className="frm" onSubmit={this.onSubmit}>
            <div className="frm-field-wrapper">
              <input
                name="password"
                onChange={this.onPasswordChange}
                className="inp inp-text"
                type="password"
                placeholder={translate('app.user.password')}
              />
              {errorMessage}
            </div>
            {showloginButton && (
              <Button
                type="submit"
                color="razzmatazz"
                size="medium"
                className="button"
                isLoading={isLoginStatusPending(this.props)}
              >
                {translate('app.email_login.login')}
              </Button>
            )}
          </form>
          <div className="v-facebook-connect">
            {translate('deeplink.signup.or')}
            <FacebookConnect
              className="inline"
              onLogin={this.onLogin}
              onSignUp={this.onFacebookSignup}
            />
          </div>
        </div>
      </div>
    );
  }

  renderEmailNotYetSend() {
    const name = nameFromProps(this.props);
    const nameWithLeadingSpaceOrNoName = name ? ` ${name}` : '';
    const notMeText = name
      ? translate('app.email_login.wrong_user', name)
      : translate('app.email_login.wrong_user_noname');

    return (
      <div className="body">
        <h1>{translate('app.email_login.header', nameWithLeadingSpaceOrNoName)}</h1>
        <Button
          color="razzmatazz"
          size="medium"
          onClick={this.onInitialSend}
          className="request-magic-login-button"
        >
          {translate('app.email_login.button')}
        </Button>
        <p>{translate('app.email_login.intro')}</p>
        <ul>
          <li>
            <Link href={`/item/${itemIdFromData(this.props)}`} onClick={onNotMe}>
              {notMeText}
            </Link>
          </li>
        </ul>
      </div>
    );
  }

  renderEmailSend() {
    return (
      <div className="body">
        <h1>{translate('app.email_login.email_send_header')}</h1>
        <ul>
          <li>{this.renderResendLink()}</li>
        </ul>
      </div>
    );
  }

  render() {
    const { isSend } = this.state;

    return (
      <div className="v-email-login-page">
        <header className="header">
          <Link href="/">
            <Logo />
          </Link>
        </header>
        <div className="login-mail">
          {isSend ? this.renderEmailSend() : this.renderEmailNotYetSend()}
        </div>
        {this.renderLoginForm()}
      </div>
    );
  }
}

export default withRouter(EmailLoginPage);
