import { history } from 'byebye';
import React from 'react';
import { anyPass, equals, prop, compose } from 'ramda';
import AltContainer from 'alt-container';
import { MANUAL_LOGOUT, LIBRARY_UNAVAILABLE } from 'app-constants';
import { loginIfAuthorized as autoFacebookLogin } from 'managers/facebook';
import Analytics from 'instances/analytics';
import { decode as decodeBase64 } from 'helpers/base64';
import Auth from 'controllers/auth';
import LoginActions from 'actions/LoginActions';
import LoginStore from 'stores/LoginStore';
import EmailLoginPage from './EmailLoginPage';

const type = prop('type');
const manualLogout = equals(MANUAL_LOGOUT);
const isLibraryUnavailable = equals(LIBRARY_UNAVAILABLE);
const isUnableToLogin = equals('UnableToLogin');
const isAllowedFacebookErrorType = anyPass([manualLogout, isLibraryUnavailable, isUnableToLogin]);
const isAllowedFacebookLoginError = compose(isAllowedFacebookErrorType, type);

export function openItemLogin(itemId, token) {
  if (Auth.getId()) {
    const url = `/item/${itemId}`;
    history.navigate(url, { trigger: true, replace: false }, { returnUrl: '/' });
    return <span />;
  }

  Analytics.track('Email login/deeplink landing');

  autoFacebookLogin()
    .then(() => {
      LoginActions.loginSuccess(null, {
        login_type: 'facebookautologin',
        platform: 'facebook',
      });
    })
    .catch(err => {
      if (isAllowedFacebookLoginError(err)) {
        return;
      }
      throw err;
    });

  const tokenData = JSON.parse(decodeBase64(token));
  const data = {
    item_id: itemId,
    user_id: tokenData.id,
    domain: tokenData.domain,
    name: tokenData.name,
  };

  return (
    <AltContainer
      component={EmailLoginPage}
      stores={{ loginState: LoginStore }}
      inject={{ data }}
    />
  );
}
